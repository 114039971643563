export default {
    async SHA256(text) {
        if (!text) {
            return ''
        }
        try {
            const msgUint8 = new TextEncoder().encode(text);
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
            const hashArray = Array.from(new Uint8Array(hashBuffer))
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
            return hashHex
        } catch (e) {
            console.error(e)
            return ''
        }
    }
}